* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes titleFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@keyframes titleGlow {
  0%, 100% {
    text-shadow: 0 0 4px rgba(59, 130, 246, 0);
  }
  50% {
    text-shadow: 0 0 8px rgba(59, 130, 246, 0.3);
  }
}

:root {
  --primary-color: #000000;
  --accent-color: #3B82F6;
  --text-primary: #ffffff;
  --text-secondary: #999999;
  --background-dark: #000000;
  --background-card: #111111;
  --border-color: #333333;
  --transition-speed: 0.3s;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  color: var(--text-primary);
  background-color: var(--background-dark);
  line-height: 1.6;
}

/* Smooth transitions */
a, button, .MuiPaper-root, .MuiCard-root {
  transition: all var(--transition-speed) ease-in-out;
}

/* Add transition for hover effects */
a, button {
  transition: transform var(--transition-speed), filter var(--transition-speed);
}

/* Selection color */
::selection {
  background: var(--accent-color);
  color: var(--primary-color);
}

#root {
  min-height: 100vh;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  letter-spacing: -0.02em;
  color: var(--text-primary);
}

/* Focus states */
:focus {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

/* Add focus-visible for better accessibility */
:focus-visible {
  outline: 2px dashed var(--accent-color);
  outline-offset: 4px;
}

/* Image handling */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Dark theme specific styles */
.MuiPaper-root {
  background-color: var(--background-card);
  color: var(--text-primary);
}

.MuiTypography-root {
  color: var(--text-primary);
}

.MuiCard-root {
  background-color: var(--background-card);
  border-color: var(--border-color);
}

/* Add subtle shadow for cards */
.MuiCard-root:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Title animations */
.title-char {
  animation: titleGlow 3s infinite;
}

.title-char:nth-child(1) { animation-delay: 0.1s; }
.title-char:nth-child(2) { animation-delay: 0.2s; }
.title-char:nth-child(3) { animation-delay: 0.3s; }
.title-char:nth-child(4) { animation-delay: 0.4s; }
.title-char:nth-child(5) { animation-delay: 0.5s; }
.title-char:nth-child(6) { animation-delay: 0.6s; }
.title-char:nth-child(7) { animation-delay: 0.7s; }
.title-char:nth-child(8) { animation-delay: 0.8s; }
.title-char:nth-child(9) { animation-delay: 0.9s; }
.title-char:nth-child(10) { animation-delay: 1.0s; }
.title-char:nth-child(11) { animation-delay: 1.1s; }

@media (prefers-reduced-motion: reduce) {
  .title-char {
    animation: none;
  }
}

/* Enhanced UX */

/* Add hover effects for buttons and links */
a:hover, button:hover {
  transform: scale(1.05);
  filter: brightness(1.2);
}

a:active, button:active {
  transform: scale(0.95);
}

/* Smooth scrolling for better navigation */
html {
  scroll-behavior: smooth;
}

/* Smooth scrolling sliver-like effect for title */
.title-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--background-dark);
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Shrink effect on scroll */
.title-container.shrink {
  transform: scale(0.9);
  opacity: 0.8;
}

/* Enhanced UX Styles */

/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: var(--accent-color);
  color: white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.3s ease;
  z-index: 1000;
}

.scroll-to-top.visible {
  opacity: 1;
  transform: translateY(0);
}

.scroll-to-top:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(59, 130, 246, 0.3);
}

/* Enhanced card interactions */
.TeamCard {
  transform-origin: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.TeamCard:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

/* Loading state animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loading {
  background: linear-gradient(90deg, 
    var(--background-card) 25%, 
    var(--border-color) 50%, 
    var(--background-card) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Enhanced section transitions */
.StyledPaper {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.StyledPaper:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 30px rgba(59, 130, 246, 0.1);
}

/* Smooth page transitions */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Responsive hover effects */
@media (hover: hover) {
  .TeamCard:hover .member-role {
    color: var(--accent-color);
    transform: translateX(8px);
  }
  
  .StyledAvatar:hover {
    transform: rotate(10deg);
  }
}
